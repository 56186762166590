import React, {useState, useEffect} from "react";
import axios from 'axios';
import LineIcon from 'react-lineicons';

function Socialicons(props){
  const [companyLinks, setCompanyLinks] = useState({});

  useEffect(() => {
    axios.get('/api/company')
      .then(response =>{
        setCompanyLinks(response.data.companyLinks);
      })
  }, [])

  return (
    <ul className={props.bordered ? 'mi-socialicons mi-socialicons-bordered' : 'mi-socialicons'}>
      {!companyLinks.arcelor ? null : <li>
        <a rel="noopener noreferrer" target="_blank" href={companyLinks.arcelor}>
          <LineIcon name="Arcelor"/>
          <img src="/images/company/arcelor.png"></img>
        </a>
      </li>}
      {!companyLinks.vale ? null : <li>
        <a rel="noopener noreferrer" target="_blank" href={companyLinks.vale}>
          <LineIcon name="vale"/>
          <img src="/images/company/vale.png"></img>
        </a>
      </li>}
      {!companyLinks.elo ? null : <li>
        <a rel="noopener noreferrer" target="_blank" href={companyLinks.elo}>
          <LineIcon name="elo"/>
          <img src="/images/company/elo.png"></img>
        </a>
      </li>}
      {!companyLinks.control ? null : <li>
        <a rel="noopener noreferrer" target="_blank" href={companyLinks.control}>
          <LineIcon name="control"/>
          <img src="/images/company/control.png"></img>
        </a>
      </li>}
      {!companyLinks.boticario ? null : <li>
        <a rel="noopener noreferrer" target="_blank" href={companyLinks.boticario}>
          <LineIcon name="boticario"/>
          <img src="/images/company/boticario.png"></img>
        </a>
      </li>}
      {!companyLinks.loox ? null : <li>
        <a rel="noopener noreferrer" target="_blank" href={companyLinks.loox}>
          <LineIcon name="loox"/>
          <img src="/images/company/loox.png"></img>
        </a>
      </li>}
      {!companyLinks.unimed ? null : <li>
        <a rel="noopener noreferrer" target="_blank" href={companyLinks.unimed}>
          <LineIcon name="unimed"/>
          <img src="/images/company/unimed.png"></img>
        </a>
      </li>}
      {!companyLinks.impacto ? null : <li>
        <a rel="noopener noreferrer" target="_blank" href={companyLinks.impacto}>
          <LineIcon name="impacto"/>
          <img src="/images/company/impacto.png"></img>
        </a>
      </li>}
      {!companyLinks.maresia ? null : <li>
        <a rel="noopener noreferrer" target="_blank" href={companyLinks.maresia}>
          <LineIcon name="maresia"/>
          <img src="/images/company/maresia.png"></img>
        </a>
      </li>}
      {!companyLinks.enel ? null : <li>
        <a rel="noopener noreferrer" target="_blank" href={companyLinks.enel}>
          <LineIcon name="enel"/>
          <img src="/images/company/enel.png"></img>
        </a>
      </li>}
      {!companyLinks.nufarm ? null : <li>
        <a rel="noopener noreferrer" target="_blank" href={companyLinks.nufarm}>
          <LineIcon name="nufarm"/>
          <img src="/images/company/nufarm.png"></img>
        </a>
      </li>}
      {!companyLinks.beq ? null : <li>
        <a rel="noopener noreferrer" target="_blank" href={companyLinks.beq}>
          <LineIcon name="beq"/>
          <img src="/images/company/beq.png"></img>
        </a>
      </li>}
      {!companyLinks.vrglass ? null : <li>
        <a rel="noopener noreferrer" target="_blank" href={companyLinks.vrglass}>
          <LineIcon name="vrglass"/>
          <img src="/images/company/vrglass.png"></img>
        </a>
      </li>}
      {!companyLinks.bugaboo ? null : <li>
        <a rel="noopener noreferrer" target="_blank" href={companyLinks.bugaboo}>
          <LineIcon name="bugaboo"/>
          <img src="/images/company/bugaboo.png"></img>
        </a>
      </li>}
    </ul>
  );
}

export default Socialicons;
