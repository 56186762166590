import Mock from "../mock";

const database = {
  information: {
    name: 'Nonato Sousa',
    aboutContent: "I am a Unity Developer with 10+ years experience. I have been working with different platforms from mobile to VR. I am also a SwiftUI Developer and I have experience publishing apps and games to App Store, Google Play and Meta Store.",
    age: 34,
    phone: '',
    nationality: 'Brazilian',
    language: 'English, Portuguese, French',
    email: 'nonato.sousa@mundsoftware.com',
    address: 'Coronel Luiz David de Souza Street, Ceara, Brazil',
    freelanceStatus: 'Available',
    socialLinks: {
      facebook: '',
      twitter: 'https://twitter.com/dinhonms',
      pinterest: '',
      behance: '',
      linkedin: 'https://www.linkedin.com/in/nonatosousanetworkprofile/',
      dribbble: '',
      github: 'https://github.com/dinhonms'
    },
    brandImage: '/images/brand-image.jpg',
    aboutImage: '/images/about-image.jpg',
    aboutImageLg: '/images/about-image-lg.jpg',
    cvfile: '/files/Resume_Nonato_Sousa.pdf'
  },
  company: {
    companyLinks: {
      arcelor: 'https://brasil.arcelormittal.com/',
      elo: 'https://www.elo.com.br/',
      vale: 'https://www.vale.com/',
      control: 'https://controlengenharia.eng.br/',
      boticario: 'https://www.boticario.com.br/',
      loox: 'https://looxstudios.com.br/',
      unimed: 'https://www.unimedfortaleza.com.br/',
      impacto: 'https://impactoprotensao.com.br/',
      maresia: 'https://www.maresia.com.br/',
      enel: 'https://www.enel.com.br/',
      nufarm: 'https://nufarm.com/',
      beq: 'https://www.beq.com.br/',
      vrglass: 'https://vrglass.com/home/',
      bugaboo: 'https://www.bugaboostudio.com/'
    }
  },
  services: [
    {
      title: "Game Development",
      icon: 'game',
      details: "6+ years developing games for mobile, Web and Desktop."
    },
    {
      title: "AR / VR Development",
      icon: 'code',
      details: "+7 years developing AR and VR experiences."
    },
    {
      title: "iOS Development",
      icon: 'mobile',
      details: "+1 year on the journey of learning and developing SwiftUI apps."
    }
  ],
  reviews: [
    {
      id: 1,
      content: "Not only the asset is very powerful (you can tweak it to your liking very easily, since it is has been very well coded), but the developer support has been beyond amazing!",
      author: {
        name: 'Carvy',
        designation: 'Client'
      }
    },
    {
      id: 2,
      content: "Excellent support service, very fast and effective in the adaptations that I have to do in the game.",
      author: {
        name: 'Andhrex',
        designation: 'Client'
      }
    },
    {
      id: 3,
      content: "Developer really helpful and give some tips about improvement.",
      author: {
        name: 'Kujai',
        designation: 'Game Asset Client'
      }
    },
    {
      id: 3,
      content: "Excellent support and easy-to-integrate package for projects, congratulations!.",
      author: {
        name: 'Rodrigo Barreto',
        designation: 'Game Asset Client'
      }
    },
    {
      id: 4,
      content: "Nonato is an amazing colleague to work with, always kind and thoughtful, hardworking and cares about the end result for the user and the best for the company. .",
      author: {
        name: 'Thiago Casarotto',
        designation: 'Product Designer UX/UI'
      }
    }
  ],
  softSkills: [
    {
      title: "Commitment",
      value: 100
    },
    {
      title: "Communication",
      value: 100
    },
    {
      title: "Passionate about Doing",
      value: 100
    },
    {
      title: "Problem-Solving",
      value: 100
    },
    {
      title: "Adaptability",
      value: 100
    },
    {
      title: "Growth Mindset",
      value: 100
    },
    {
      title: "Solicitous",
      value: 100
    },
    {
      title: "Self-Awareness",
      value: 100
    },
    {
      title: "Open-Mindedness",
      value: 100
    }
  ],
  skills: [
    {
      title: "Unity",
      value: 85
    },
    {
      title: "Game Dev",
      value: 70
    },
    {
      title: "AR",
      value: 80
    },
    {
      title: "VR",
      value: 80
    },
    {
      title: "C#",
      value: 78
    },
    {
      title: "SwiftUI",
      value: 30
    },
    {
      title: "Design Patters",
      value: 75
    },
    {
      title: "SDK",
      value: 90
    },
    {
      title: "OOP",
      value: 85
    },
    {
      title: "Web Development",
      value: 35
    },
    {
      title: "REST",
      value: 85
    },
    {
      title: "VisionLib",
      value: 85
    },
    {
      title: "Optimization",
      value: 90
    },
    {
      title: "GIMP, Photoshop",
      value: 35
    },
    {
      title: "Unity iOS Plugins",
      value: 75
    },
    {
      title: "Asset Store Developer",
      value: 80
    }
  ],
  portfolios: [
    {
      id: 1,
      title: "Exercises for Kids",
      subtitle: "A game designed for children to learn while having fun - Desktop, Web,iOS, Android.",
      imageUrl: "/images/exercises/pc.png",
      largeImageUrl: [
        "/images/exercises/pc.png",
        "/images/exercises/ios-1.png",
        "/images/exercises/ios-2.png",
        "/images/exercises/ios-3.png",
        "/images/exercises/android-1.png",
        "/images/exercises/android-2.png",
        "/images/exercises/android-3.png"
      ],
      url: 'https://apps.microsoft.com/detail/9NBLGGH5GFZV?hl=it-it&gl=US'
    },
    {
      id: 2,
      title: "Bad Snow",
      subtitle: "An infinity run mobile game - Web, iOS, Android.",
      imageUrl: "/images/bad_snow/ios-3.png",
      largeImageUrl: [
        "/images/bad_snow/ios-3.png",
        "/images/bad_snow/ios-1.png",
        "/images/bad_snow/ios-2.png",
        "/images/bad_snow/android-1.png",
        "/images/bad_snow/android-2.png",
        "/images/bad_snow/android-3.png"
      ],
      url: 'https://www.facebook.com/gamebadsnow/?paipv=0&eav=AfYSIyBuBhD9e6aNiEH0kT-Kj9C9GTXEXrh7Q7BvBfJSCSWG470B9UUxgcJ4D0wN9GI&_rdr'
    },
    {
      id: 2,
      title: "Rabbit Fire",
      subtitle: "An adventure platform game - Desktop, Web, iOS, Android.",
      imageUrl: "/images/rabbit_fire/pc.png",
      largeImageUrl: [
        "/images/rabbit_fire/pc.png",
        "/images/rabbit_fire/ios-1.png",
        "/images/rabbit_fire/ios-2.png",
        "/images/rabbit_fire/ios-3.png",
        "/images/rabbit_fire/android-1.png",
        "/images/rabbit_fire/android-2.png",
        "/images/rabbit_fire/android-3.png"
      ],
      url: 'https://rabbit-fire-adventure-begins.apk.cafe/'
    },
    {
      id: 4,
      title: "Jornada Unimed - Web, iOS, Android",
      subtitle: "An advergame game",
      imageUrl: "/images/unimed_jornada/pc.png",
      largeImageUrl: [
        "/images/unimed_jornada/pc.png",
        "/images/unimed_jornada/ios-1.png",
        "/images/unimed_jornada/ios-2.png",
        "/images/unimed_jornada/ios-3.png",
        "/images/unimed_jornada/android-1.png",
        "/images/unimed_jornada/android-2.png",
        "/images/unimed_jornada/android-3.png"
      ],
      url: 'https://apps.apple.com/br/app/jornada-o-jogo-da-excel%C3%AAncia/id1173730091'
    },
    {
      id: 5,
      title: "Iron Knights",
      subtitle: "A party game - Desktop, Console",
      imageUrl: "/images/iron_knights/pc.png",
      largeImageUrl: [
        "/images/iron_knights/pc.png",
        "/images/iron_knights/pc-2.png"
      ],
      url: 'https://www.instagram.com/volansstudio/'
    },
    {
      id: 6,
      title: "Boa Gestao",
      subtitle: "A City management game developed to a Brazilian game contest - Desktop, Web, iOS, Android",
      imageUrl: "/images/boa_gestao/ios-1.png",
      largeImageUrl: [
        "/images/boa_gestao/pc.png",
        "/images/boa_gestao/ios-1.png",
        "/images/boa_gestao/ios-2.png",
        "/images/boa_gestao/ios-3.png",
        "/images/boa_gestao/android-1.png",
        "/images/boa_gestao/android-2.png",
        "/images/boa_gestao/android-3.png"
      ]
    },
    {
      id: 7,
      title: "BR Oil",
      subtitle: "An oil platform management game developed to a Brazilian game contest - Desktop, Web, iOS, Android.",
      imageUrl: "/images/br_oil/pc.png",
      largeImageUrl: [
        "/images/br_oil/pc.png",
        "/images/br_oil/ios-1.png",
        "/images/br_oil/ios-2.png",
        "/images/br_oil/android-1.png",
        "/images/br_oil/android-2.png",
        "/images/br_oil/android-3.png"
      ]
    },
    {
      id: 8,
      title: "Ermes",
      subtitle: "App for remote assistance in AR - iOS, Android.",
      imageUrl: "/images/ermes/ios-2.png",
      largeImageUrl: [
        "/images/ermes/ios-1.png",
        "/images/ermes/ios-2.png",
        "/images/ermes/ios-3.png",
        "/images/ermes/android-1.png",
        "/images/ermes/android-2.png",
        "/images/ermes/android-3.png"
      ],
      url: 'https://apps.apple.com/br/app/ermes/id1447043805'
    },
    {
      id: 9,
      title: "Customize Car AR",
      subtitle: "App for customizing Real vehicles or Digital Twin in AR - iOS, Android.",
      imageUrl: "/images/accex_ar/ios-4.png",
      largeImageUrl: [
        "/images/accex_ar/ios-1.png",
        "/images/accex_ar/ios-2.png",
        "/images/accex_ar/ios-3.png",
        "/images/accex_ar/ios-4.png",
        "/images/accex_ar/android-1.png",
        "/images/accex_ar/android-2.png",
        "/images/accex_ar/android-3.png"
      ],
      url: 'https://play.google.com/store/apps/details?id=com.antikytera.accexar'
    },
    {
      id: 10,
      title: "Maresia",
      subtitle: "An AR App for a cloth collection - iOS, Android",
      imageUrl: "/images/maresia/ios-1.png",
      largeImageUrl: [
        "/images/maresia/ios-1.png",
        "/images/maresia/ios-2.png",
        "/images/maresia/ios-3.png",
        "/images/maresia/android-1.png",
        "/images/maresia/android-2.png",
        "/images/maresia/android-3.png"
      ],
      url: 'https://play.google.com/store/apps/details?id=com.bugaboo.maresiaar&hl=en_US&gl=US'
    },
    {
      id: 11,
      title: "Nufarm",
      subtitle: "An AR experience enphansizeing the importance of a sustainable environment - iOS, Android.",
      imageUrl: "/images/nufarm/ios-1.png",
      largeImageUrl: [
        "/images/nufarm/ios-1.png",
        "/images/nufarm/ios-2.png",
        "/images/nufarm/android-1.png",
        "/images/nufarm/android-2.png"
      ],
      url: 'https://play.google.com/store/apps/details?id=com.bugaboo.nufarmflora&hl=en_US&gl=US'
    },
    {
      id: 11,
      title: "Elo",
      subtitle: "An AR app to show the benefits of Elo cards. - iOS, Android.",
      imageUrl: "/images/elo/ios-1.png",
      largeImageUrl: ["/images/elo/ios-1.png"],
      url: 'https://vrglass.com/home/portfolio/elo/'
    }
  ],
  experience: {
    workingExperience: [
      {
        id: 1,
        year: "2020 - Present",
        position: "Unity Developer",
        company: "Antikytera e-Technologies",
        details: "Working as Unity Developer focused in AR and Communications Team Lead. I have achieved a great performance, achieving both company and my personal goals."
      },
      {
        id: 2,
        year: "2016 - 2020",
        position: "Team Lead",
        company: "Bugaboo Studio",
        details: "Worked as a Team Lead in various AR and VR Experiences for different clients using different technologies like Kinect, Vive, Oculus, Cardboard, 8th Wall, Vuforia, AR Kit and AR Core."
      },
      {
        id: 1,
        year: "2017 - Present",
        position: "Asset Store Developer",
        company: "Assets For Devs · Self-employed",
        details: "Developing assets for developers and publishing through Unity Asset Store."
      },
      {
        id: 3,
        year: "2014 - 2016",
        position: "Bitllab",
        company: "Principal Developer",
        details: "Worked as gameplay programmer, system programmer and UI programmer. Also developed business ads mechanics and improved existing games."
      }
    ],
    educationExperience: [
      {
        id: 1,
        year: "2019 - 2021",
        graduation: "Software Engineering",
        university: "Estacio University",
        details: "Post-Graduate in Software Engineering"
      },
      {
        id: 2,
        year: "2012 - 2015",
        graduation: "Game Development",
        university: "Estacio University",
        details: "Undergraduate in Digital Games Technology"
      }
    ]
  },
  // blogs: [
  //   {
  //     id: 1,
  //     title: 'Markdown & Html supported blog.',
  //     featuredImage: '/images/blog-image-1.jpg',
  //     filesource: '../../blog/markdown-html-supported-blog.md',
  //     createDay: "20",
  //     createMonth: 'February',
  //     createYear: "2020"
  //   },
  //   {
  //     id: 2,
  //     title: 'Installing NodeJS on your device.',
  //     featuredImage: '/images/blog-image-2.jpg',
  //     filesource: '../../blog/installing-nodejs-on-your-device.md',
  //     createDay: "20",
  //     createMonth: 'February',
  //     createYear: "2020"
  //   },
  //   {
  //     id: 3,
  //     title: 'UI/UX design starter with Adobe XD.',
  //     featuredImage: '/images/blog-image-3.jpg',
  //     filesource: '../../blog/uiux-design-starter-with-adobe-xd.md',
  //     createDay: "20",
  //     createMonth: 'February',
  //     createYear: "2020"
  //   },
  //   {
  //     id: 4,
  //     title: 'Boost your post for increasing sales.',
  //     featuredImage: '/images/blog-image-4.jpg',
  //     filesource: '../../blog/boost-your-post-for-increasing-sales.md',
  //     createDay: "20",
  //     createMonth: 'February',
  //     createYear: "2020"
  //   },
  //   {
  //     id: 5,
  //     title: 'Difference between GatsbyJS & NextJS.',
  //     featuredImage: '/images/blog-image-5.jpg',
  //     filesource: '../../blog/difference-between-gatsbyjs-and-nextjs.md',
  //     createDay: "20",
  //     createMonth: 'February',
  //     createYear: "2020"
  //   },
  //   {
  //     id: 6,
  //     title: 'How to choose javascript framework for project.',
  //     featuredImage: '/images/blog-image-6.jpg',
  //     filesource: '../../blog/how-to-choose-javascript-framework-for-project.md',
  //     createDay: "20",
  //     createMonth: 'February',
  //     createYear: "2020"
  //   },
  //   {
  //     id: 7,
  //     title: 'Web automation with python language.',
  //     featuredImage: '/images/blog-image-7.jpg',
  //     filesource: '../../blog/web-automation-with-python-language.md',
  //     createDay: "20",
  //     createMonth: 'February',
  //     createYear: "2020"
  //   },
  //   {
  //     id: 8,
  //     title: 'Time to use latest technology for creating a website.',
  //     featuredImage: '/images/blog-image-8.jpg',
  //     filesource: '../../blog/time-to-use-latest-technology-for-creating-a-website.md',
  //     createDay: "20",
  //     createMonth: 'February',
  //     createYear: "2020"
  //   },
  //   {
  //     id: 9,
  //     title: 'Think out of the box.',
  //     featuredImage: '/images/blog-image-9.jpg',
  //     filesource: '../../blog/think-out-of-the-box.md',
  //     createDay: "20",
  //     createMonth: 'February',
  //     createYear: "2020"
  //   },
  //   {
  //     id: 10,
  //     title: 'Trending designs in 2020.',
  //     featuredImage: '/images/blog-image-1.jpg',
  //     filesource: '../../blog/trending-designs-in-2020.md',
  //     createDay: "20",
  //     createMonth: 'February',
  //     createYear: "2020"
  //   },
  //   {
  //     id: 11,
  //     title: 'How to get 10k instagram followers?',
  //     featuredImage: '/images/blog-image-2.jpg',
  //     filesource: '../../blog/how-to-get-10k-instagram-followers.md',
  //     createDay: "20",
  //     createMonth: 'February',
  //     createYear: "2020"
  //   },
  //   {
  //     id: 12,
  //     title: 'What NodeJS can do?',
  //     featuredImage: '/images/blog-image-3.jpg',
  //     filesource: '../../blog/what-nodejs-can-do.md',
  //     createDay: "20",
  //     createMonth: 'February',
  //     createYear: "2020"
  //   },
  //   {
  //     id: 13,
  //     title: 'Futures of javascript.',
  //     featuredImage: '/images/blog-image-4.jpg',
  //     filesource: '../../blog/future-of-javascript.md',
  //     createDay: "20",
  //     createMonth: 'February',
  //     createYear: "2020"
  //   },
  //   {
  //     id: 14,
  //     title: 'Popular javascript library in 2020.',
  //     featuredImage: '/images/blog-image-5.jpg',
  //     filesource: '../../blog/popular-javascript-library-in-2020.md',
  //     createDay: "20",
  //     createMonth: 'February',
  //     createYear: "2020"
  //   },
  //   {
  //     id: 15,
  //     title: 'Promrammers must read books.',
  //     featuredImage: '/images/blog-image-6.jpg',
  //     filesource: '../../blog/programmers-must-read-books.md',
  //     createDay: "20",
  //     createMonth: 'February',
  //     createYear: "2020"
  //   }
  // ],
  contactInfo: {
    phoneNumbers: ['+5585998026245'],
    emailAddress: ['nonato.sousa@mundsoftware.com'],
    address: "Coronel Luiz David de Souza Street, Ceara, Brazil"
  }
}

Mock.onGet("/api/company").reply(config => {
  const response = database.company;
  return [200, response];
});

Mock.onGet("/api/information").reply(config => {
  const response = database.information;
  return [200, response];
});

Mock.onGet("/api/services").reply(config => {
  const response = database.services;
  return [200, response];
});

Mock.onGet("/api/reviews").reply(config => {
  const response = database.reviews;
  return [200, response];
});

Mock.onGet("/api/trusted").reply(config => {
  const response = database.trusted;
  return [200, response];
});

Mock.onGet("/api/soft_skills").reply(config => {
  const response = database.softSkills;
  return [200, response];
});

Mock.onGet("/api/skills").reply(config => {
  const response = database.skills;
  return [200, response];
});

Mock.onGet("/api/portfolios").reply(config => {
  const response = database.portfolios;
  return [200, response];
});

Mock.onGet("/api/experience").reply(config => {
  const response = database.experience;
  return [200, response];
});

// Mock.onGet("/api/blog").reply(config => {
//   const response = database.blogs;
//   return [200, response];
// });

Mock.onGet("/api/contactinfo").reply(config => {
  const response = database.contactInfo;
  return [200, response];
});